import { FC,ReactNode } from 'react';
import { PlusIcon } from './icon';
import cls from './styles.module.scss';

interface TextButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
}

export const TextButton: FC<TextButtonProps> = ({ children,...props }) => {

  return (<button className={cls['text-button']} {...props}><PlusIcon />{children}</button>);
};
