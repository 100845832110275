import { observer } from 'mobx-react-lite';
import { FC,useCallback,useEffect,useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from 'store';
import { EInputStyleVariant,Input } from 'ui-new/input';
import { Modal } from 'widgets/modal';
import cls from './style.module.scss';
import { ModalButtons } from 'widgets/modal/buttons';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ValidationMessages,ValidationTypes } from 'assets/utils/validationMessages';
import { FieldTypes } from '../types';

const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

const schema = yup.object({
  pay: yup.number().typeError(() => ValidationMessages.get(ValidationTypes.number)).positive(ValidationMessages.get(ValidationTypes.positive))
    .max(999999.99,({ max }) => `${ValidationMessages.get(ValidationTypes.max)} ${max}`)
    .test(
      "is-decimal",
      "Максимум 2 знака после запятой",
      (val: any) => {
        if (val !== undefined) {
          return patternTwoDigisAfterComma.test(val);
        }
        return true;
      }
    )
    .required(ValidationMessages.get(ValidationTypes.required))
});

interface FormValues {
  pay: number;
  pay_gros?: number;
  pay_net?: number;
}

interface BonusModalProps {

}

const employeeTypes: { [key: number]: string; } = { 1: 'трудоустроенный',2: 'самозанятый' };
const fieldTitles: { [key: string]: string; } = {
  'plan': 'Вознаграждение по плану','fact': 'Введи вознаграждение по факту',
  'approved': 'Утвержденное вознаграждение','paid': 'Выплаченное вознаграждение'
};

const BonusModalComponent: FC<BonusModalProps> = () => {
  const { RootStore: { SalaryFundStore: { setBonusModalIsOpen,selectedBonus,
    selectedEmployee,setSelectedEmployee,
    selectedField,currentBonusStatus: currentStatus,
    fetchUpdateBonuses,ndfl } } } = useStore();
  const { control,setValue,watch,getValues,handleSubmit } = useForm<FormValues>({
    defaultValues: (selectedBonus && selectedField) ? {
      pay: selectedBonus[`${selectedField}_gross`] ?? 0,
      pay_net: selectedBonus[`${selectedField}_net`] ?? 0,
      pay_gros: (selectedBonus[`${selectedField}_gross`] ?? 0) - (selectedBonus[`${selectedField}_net`] ?? 0),
    } : {},
    reValidateMode: 'onChange',
    resolver: yupResolver(schema)
  });

  const payDisabled = useMemo(() => {
    switch (selectedField) {
      case FieldTypes['plan']:
        return currentStatus?.value !== 1;
      case FieldTypes['fact']:
        return currentStatus?.value !== 2;
      default:
        return true;
    }
  },[currentStatus,selectedField]);

  const onSubmitHandler = (val: FormValues) => {
    if (!selectedBonus || !selectedField) return;
    fetchUpdateBonuses({ bonusAward: selectedBonus.id,sum: val.pay,type: selectedField }).then(success => success && onClose());
  };
  const employeeType = useMemo(() => !selectedEmployee ? 'не задан' : employeeTypes[selectedEmployee.employee_type] ?? 'не задан',[selectedEmployee]);
  const fi = useMemo(() => !selectedEmployee ? 'Не выбран' : `${selectedEmployee.surname} ${selectedEmployee.name}`,[selectedEmployee]);

  const ratio = useMemo(() => selectedEmployee?.employee_type === 1 ? ndfl : 1,[ndfl,selectedEmployee?.employee_type]);

  useEffect(() => {
    const pay = getValues().pay;
    if (isNaN(Number(pay))) return;
    const net = Math.round(pay * ratio * 100) / 100;
    const gross = Math.round(pay * (1 - ratio) * 100) / 100;
    setValue('pay_net',net);
    setValue('pay_gros',gross);
  },[watch('pay'),ratio]);

  const onClose = useCallback(() => {
    setSelectedEmployee();
    setBonusModalIsOpen(false,null);
  },[setBonusModalIsOpen,setSelectedEmployee]);

  return (<Modal minWidth='498px' maxWidth='498px' title={!selectedField ? 'Вознаграждение' : fieldTitles[selectedField]} onClose={onClose}>
    <div className={cls['form']}>
      <div className={cls['employee-status']}>
        <p>Работник: {fi}</p>
        <p>Тип выплаты: {employeeType}</p>
      </div>
      <Input control={control} variant={EInputStyleVariant['basicInput']} type={'number'} disabled={payDisabled}
        label={`Вознаграждение${selectedEmployee?.employee_type === 1 ? ' с учетом НДФЛ' : ''}, ₽`}
        placeholder='0 ₽' name='pay'
      />
      <div className={selectedEmployee?.employee_type === 1 ? cls['row'] : ''}>
        <Input variant={EInputStyleVariant['basicInput']} disabled label='На руки, ₽'
          placeholder='0 ₽' control={control} name='pay_net' type={'number'} />
        {selectedEmployee?.employee_type === 1 &&
          <Input variant={EInputStyleVariant['basicInput']} disabled label='НДФЛ, ₽' placeholder='0 ₽' control={control} name='pay_gros' type={'number'} />
        }
      </div>
      <div style={{ alignSelf: 'flex-end',marginTop: '16px',marginBottom: '-20px' }}>
        <ModalButtons primaryButtonLabel='Сохранить' onPrimaryButtonClick={handleSubmit(onSubmitHandler)}
          secondaryButtonLabel='Отменить' onSecondaryButtonClick={onClose} />
      </div>
    </div>
  </Modal>);
};

export const BonusModal = observer(BonusModalComponent);