import { observer } from 'mobx-react-lite';
import cls from '../styles.module.scss';
import { useEffect,useMemo } from 'react';
import { EmployeesTable } from './employees-table';
import { addSpaces } from 'assets/utils';
import { useStore } from 'store';

const GeneralInformationComponent = () => {
  const { RootStore: { SalaryFundStore: { selectedDate,fetchGetAwards,generalTableItems: { users,helpers } } } } = useStore();

  useEffect(() => {
    fetchGetAwards();
  },[fetchGetAwards,selectedDate]);

  const footerIsVisible = helpers.length > 0 && users.length > 0;
  const sum = useMemo(() => {
    if (!footerIsVisible) return [0,0,0,0,0,0,0,0,0];

    let monthPlan = 0;
    let monthApproved = 0;
    let monthPay = 0;
    let firstPeriodPlan = 0;
    let firstPeriodApproved = 0;
    let firstPeriodPay = 0;
    let secondPeriodPlan = 0;
    let secondPeriodApproved = 0;
    let secondPeriodPay = 0;

    users.forEach((val) => {
      monthPlan += val[`monthly_plan`] ?? 0;
      monthApproved += val[`monthly_approved`] ?? 0;
      monthPay += val[`monthly_pay`] ?? 0;
      firstPeriodPlan += val[`first_period_plan`] ?? 0;
      firstPeriodApproved += val[`first_period_approved`] ?? 0;
      firstPeriodPay += val[`first_period_ndfl`] ?? 0;
      secondPeriodPlan += val[`second_period_plan`] ?? 0;
      secondPeriodApproved += val[`second_period_approved`] ?? 0;
      secondPeriodPay += val[`second_period_ndfl`] ?? 0;
    });
    helpers.forEach((val) => {
      monthPlan += val[`monthly_plan`] ?? 0;
      monthApproved += val[`monthly_approved`] ?? 0;
      monthPay += val[`monthly_pay`] ?? 0;
      firstPeriodPlan += val[`first_period_plan`] ?? 0;
      firstPeriodApproved += val[`first_period_approved`] ?? 0;
      firstPeriodPay += val[`first_period_ndfl`] ?? 0;
      secondPeriodPlan += val[`second_period_plan`] ?? 0;
      secondPeriodApproved += val[`second_period_approved`] ?? 0;
      secondPeriodPay += val[`second_period_ndfl`] ?? 0;
    });

    monthPlan = Math.floor(monthPlan * 100) / 100;
    monthApproved = Math.floor(monthApproved * 100) / 100;
    monthPay = Math.floor(monthPay * 100) / 100;
    firstPeriodPlan = Math.floor(firstPeriodPlan * 100) / 100;
    firstPeriodApproved = Math.floor(firstPeriodApproved * 100) / 100;
    firstPeriodPay = Math.floor(firstPeriodPay * 100) / 100;
    secondPeriodPlan = Math.floor(secondPeriodPlan * 100) / 100;
    secondPeriodApproved = Math.floor(secondPeriodApproved * 100) / 100;
    secondPeriodPay = Math.floor(secondPeriodPay * 100) / 100;

    return [monthPlan,monthApproved,monthPay,firstPeriodPlan,firstPeriodApproved,firstPeriodPay,secondPeriodPlan,secondPeriodApproved,secondPeriodPay];
  },[footerIsVisible,users,helpers]);
  const sumData = useMemo(() => sum.map(s => <td className={cls['td']}>{addSpaces(s)}</td>),[sum]);

  return (
    <div>
      {users.length > 0 && <EmployeesTable title='Работники' className={cls['table-rounding__top']} generalItems={users} />}
      {helpers.length > 0 && <EmployeesTable title='Хелперы' className={users.length === 0 ? cls['table-rounding__top'] : ''} generalItems={helpers} />}
      {footerIsVisible && <table style={{ position: 'sticky',bottom: '0' }} className={`${cls['table']} ${cls['table-rounding__bottom']}`}>
        <colgroup>
          <col width={44} />
          <col width={207} />
          <col width={181} />
          <col width={112} />
          <col width={112} />
          <col width={112} />
          <col width={112} />
          <col width={112} />
          <col width={112} />
          <col width={112} />
          <col width={112} />
          <col width={112} />
        </colgroup>
        <tbody className={`${cls['tfoot']} ${cls['total-tfoot']} ${cls['rounding']}`}>
          <tr className={cls['tr']}>
            <td className={cls['td']} colSpan={3}>Всего</td>
            {sumData}
          </tr>
        </tbody>
      </table>}
    </div>
  );
};

export const GeneralInformation = observer(GeneralInformationComponent);