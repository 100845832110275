import { type ReactNode,useCallback,useState } from "react";
import { type Placement,useFloating,offset as contentOffset,shift } from "@floating-ui/react";
import { createPortal } from "react-dom";
import cls from './tooltip.module.scss';

export interface Props {
    activator?: ReactNode;
    tooltip?: ReactNode;
    text?: string;
    disabled?: boolean;
    placement?: Placement;
    teleport?: HTMLElement,
    offset?: number;
    contentWidth?: string;
    activatorClassName?: string;
}

export const Tooltip = (props: Props) => {

    const {
        text,
        activator,
        activatorClassName,
        tooltip,
        disabled = false,
        placement = 'bottom',
        offset = 0,
        contentWidth = '328px',
        teleport = document.body,
    } = props;

    const [isHover,setHover] = useState(false);

    const { refs,floatingStyles } = useFloating({
        placement: placement,
        middleware: [contentOffset(offset),shift()],
    });

    const contentStyles = useCallback(() => ({
        maxWidth: contentWidth,
        ...floatingStyles,
    }),[]);

    return (
        <div
            className={cls.tooltipWrapper}
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <div ref={refs.setReference} className={`${cls.reference} ${activatorClassName ?? ''}`}>
                <div className={cls.activatorWrapper}>
                    {activator}
                </div>
            </div>

            {
                isHover && !disabled &&
                createPortal(
                    <div ref={refs.setFloating} className={cls.tooltipContent} style={floatingStyles}>
                        {tooltip || <span className={cls.textContent}>{text}</span>}
                    </div>,teleport
                )
            }
        </div>
    );
};