import { FC,useMemo } from 'react';
import cls from '../styles.module.scss';
import { addSpaces } from 'assets/utils';
import { observer } from 'mobx-react-lite';
import { IGeneralTableItem } from '../types';

interface EmployeesTableProps {
  className?: string;
  title: string;

  generalItems: IGeneralTableItem[];
}

const EmployeesTableComponent: FC<EmployeesTableProps> = ({ className,title,generalItems }) => {
  const sum = useMemo(() => {
    let monthPlan = 0;
    let monthApproved = 0;
    let monthPay = 0;
    let firstPeriodPlan = 0;
    let firstPeriodApproved = 0;
    let firstPeriodPay = 0;
    let secondPeriodPlan = 0;
    let secondPeriodApproved = 0;
    let secondPeriodPay = 0;

    generalItems.forEach((val) => {
      monthPlan += val[`monthly_plan`] ?? 0;
      monthApproved += val[`monthly_approved`] ?? 0;
      monthPay += val[`monthly_pay`] ?? 0;
      firstPeriodPlan += val[`first_period_plan`] ?? 0;
      firstPeriodApproved += val[`first_period_approved`] ?? 0;
      firstPeriodPay += val[`first_period_ndfl`] ?? 0;
      secondPeriodPlan += val[`second_period_plan`] ?? 0;
      secondPeriodApproved += val[`second_period_approved`] ?? 0;
      secondPeriodPay += val[`second_period_ndfl`] ?? 0;
    });

    monthPlan = Math.floor(monthPlan * 100) / 100;
    monthApproved = Math.floor(monthApproved * 100) / 100;
    monthPay = Math.floor(monthPay * 100) / 100;
    firstPeriodPlan = Math.floor(firstPeriodPlan * 100) / 100;
    firstPeriodApproved = Math.floor(firstPeriodApproved * 100) / 100;
    firstPeriodPay = Math.floor(firstPeriodPay * 100) / 100;
    secondPeriodPlan = Math.floor(secondPeriodPlan * 100) / 100;
    secondPeriodApproved = Math.floor(secondPeriodApproved * 100) / 100;
    secondPeriodPay = Math.floor(secondPeriodPay * 100) / 100;
    return [monthPlan,monthApproved,monthPay,firstPeriodPlan,firstPeriodApproved,firstPeriodPay,secondPeriodPlan,secondPeriodApproved,secondPeriodPay];
  },[generalItems]);

  const sumData = useMemo(() => sum.map(s => <td className={cls['td']}>{addSpaces(s)}</td>),[sum]);
  const employeesData = useMemo(() => generalItems.map((award,i) => {
    const { user,job } = award;
    return <tr>
      <td className={`${cls['td']} ${cls['employee']} ${cls['num']}`}>{i + 1}</td>
      <td className={`${cls['td']} ${cls['employee']}`}><span title={user}>{user}</span></td>
      <td className={`${cls['td']} ${cls['employee']}`}><span title={job}>{job}</span></td>
      <td className={`${cls['td']}`}>{addSpaces(award['monthly_plan'] ?? 0)}</td>
      <td className={`${cls['td']}`}>{addSpaces(award['monthly_approved'] ?? 0)}</td>
      <td className={`${cls['td']}`}>{addSpaces(award['monthly_pay'] ?? 0)}</td>
      <td className={`${cls['td']}`}>{addSpaces(award['first_period_plan'] ?? 0)}</td>
      <td className={`${cls['td']}`}>{addSpaces(award['first_period_approved'] ?? 0)}</td>
      <td className={`${cls['td']}`}>{addSpaces(award['first_period_ndfl'] ?? 0)}</td>
      <td className={`${cls['td']}`}>{addSpaces(award['second_period_plan'] ?? 0)}</td>
      <td className={`${cls['td']}`}>{addSpaces(award['second_period_approved'] ?? 0)}</td>
      <td className={`${cls['td']}`}>{addSpaces(award['second_period_ndfl'] ?? 0)}</td>
    </tr>;
  }
  ),[generalItems]);

  return (
    <table className={`${cls['table']} ${className ?? ''}`}>
      <colgroup>
        <col width={44} />
        <col width={207} />
        <col width={181} />
        <col width={112} />
        <col width={112} />
        <col width={112} />
        <col width={112} />
        <col width={112} />
        <col width={112} />
        <col width={112} />
        <col width={112} />
        <col width={112} />
      </colgroup>
      <thead className={`${cls['thead']} ${cls['rounding']}`}>
        <tr className={cls['tr']}>
          <th className={cls['th']} colSpan={3}>{title}</th>
          <th className={cls['th']} colSpan={3}>За месяц</th>
          <th className={cls['th']} colSpan={3}>Аванс</th>
          <th className={cls['th']} colSpan={3}>Зарплата</th>
        </tr>
        <tr className={cls['tr']}>
          <th className={cls['th']}>№</th>
          <th className={cls['th']}>Работник</th>
          <th className={cls['th']}>Должность</th>
          <th className={cls['th']}>Запланировано</th>
          <th className={cls['th']}>Утверждено</th>
          <th className={cls['th']}>Выплачено</th>
          <th className={cls['th']}>Запланировано</th>
          <th className={cls['th']}>Утверждено</th>
          <th className={cls['th']}>Выплачено</th>
          <th className={cls['th']}>Запланировано</th>
          <th className={cls['th']}>Утверждено</th>
          <th className={cls['th']}>Выплачено</th>
        </tr>
      </thead>
      <tfoot className={cls['tfoot']}>
        <tr className={cls['tr']}>
          <td className={cls['td']} colSpan={3}>Всего</td>
          {sumData}
        </tr>
      </tfoot>
      <tbody className={cls['tbody']}>
        {employeesData}
      </tbody>
    </table>
  );
};

export const EmployeesTable = observer(EmployeesTableComponent);