import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { useStore } from 'store';
import { BonusModal } from './bonus-modal';
import { ManagerModal } from './manager-modal';

interface ModalsProps {

}

const ModalsComponent: FC<ModalsProps> = () => {
  const { RootStore: { SalaryFundStore: { bonusModalIsOpen,managerModalIsOpen } } } = useStore();

  return (<>
    {bonusModalIsOpen && <BonusModal />}
    {managerModalIsOpen && <ManagerModal />}
  </>);
};

export const Modals = observer(ModalsComponent);