import { observer } from 'mobx-react-lite';
import { FC,useCallback,useEffect,useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useStore } from 'store';
import { EInputStyleVariant,Input } from 'ui-new/input';
import { Modal } from 'widgets/modal';
import cls from './style.module.scss';
import { ModalButtons } from 'widgets/modal/buttons';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { ValidationMessages,ValidationTypes } from 'assets/utils/validationMessages';

const patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;

const schema = yup.object({
  pay: yup.number().typeError(() => ValidationMessages.get(ValidationTypes.number)).positive(ValidationMessages.get(ValidationTypes.positive))
    .max(999999.99,({ max }) => `${ValidationMessages.get(ValidationTypes.max)} ${max}`)
    .test(
      "is-decimal",
      "Максимум 2 знака после запятой",
      (val: any) => {
        if (val !== undefined) {
          return patternTwoDigisAfterComma.test(val);
        }
        return true;
      }
    )
    .required(ValidationMessages.get(ValidationTypes.required))
});

interface FormValues {
  pay: number;
  pay_gros?: number;
  pay_net?: number;
}

interface ManagerModalProps {

}

const employeeTypes: { [key: number]: string; } = { 1: 'трудоустроенный',2: 'самозанятый' };
const fieldTitles: { [key: string]: { [key: string]: string; }; } = {
  'prepaid': {
    'plan': 'Аванс - вознаграждение по плану',
    'fact': 'Аванс - вознаграждение по факту',
  },
  'pay': {
    'plan': 'Зарплата - вознаграждение по плану',
    'fact': 'Зарплата - вознаграждение по факту',
  },
  'sum': {
    'approved': 'Утвержденное вознаграждение',
    'paid': 'Выплаченное вознаграждение'
  },
};

const ManagerModalComponent: FC<ManagerModalProps> = () => {
  const { RootStore: { SalaryFundStore: { setManagerModalIsOpen,setSelectedEmployee,selectedField,selectedEmployee,fetchUpdateManagers,managerPaidType,ndfl,managerCurrentStatus,currentManagerValue } } } = useStore();
  const ratio = useMemo(() => selectedEmployee?.employee_type === 1 ? ndfl : 1,[ndfl,selectedEmployee?.employee_type]);
  const { control,setValue,watch,getValues,handleSubmit } = useForm<FormValues>({
    defaultValues: {
      pay: currentManagerValue ?? 0,
      pay_net: (currentManagerValue ?? 0) * ratio,
      pay_gros: !currentManagerValue ? 0 : currentManagerValue - (currentManagerValue * ratio)
    },
    reValidateMode: 'onChange',
    resolver: yupResolver(schema)
  });
  const employeeType = useMemo(() => !selectedEmployee ? 'не задан' : employeeTypes[selectedEmployee.employee_type] ?? 'не задан',[selectedEmployee]);
  const fi = useMemo(() => !selectedEmployee ? 'Не выбран' : `${selectedEmployee.surname} ${selectedEmployee.name}`,[selectedEmployee]);

  const fieldDisabled = useMemo(() => {
    //1  = plan && prepaid;
    //5 = plan && pay || fact && prepaid
    //8 = fact && pay

    switch (managerCurrentStatus?.value) {
      case 1:
        if (managerPaidType === 'prepaid' && selectedField === 'plan')
          return false;
        return true;
      case 5:
        if ((managerPaidType === 'pay' && selectedField === 'plan') || (managerPaidType === 'prepaid' && selectedField === 'fact'))
          return false;
        return true;
      case 8:
        if (managerPaidType === 'pay' && selectedField === 'fact')
          return false;
        return true;
      default:
        return true;
    }

  },[managerCurrentStatus?.value,managerPaidType,selectedField]);

  const title = useMemo(() => {
    console.log(managerPaidType,selectedField);


    if (managerPaidType === null || selectedField === null) return null;

    return fieldTitles[managerPaidType][selectedField];
  },[managerPaidType,selectedField]);

  const onSubmit = (values: FormValues) => {
    if (!(['plan','fact'] as any).includes(selectedField) || managerPaidType === 'sum') return;
    let field = managerPaidType === 'prepaid' ? 'first_period' : 'second_period';
    field += `_${selectedField}`;
    fetchUpdateManagers({ field,id: '',month: '',value: values.pay });
  };

  useEffect(() => {
    const pay = getValues().pay;
    if (isNaN(Number(pay))) return;

    const net = Math.round(pay * ratio * 100) / 100;
    const gross = Math.round(pay * (1 - ratio) * 100) / 100;
    setValue('pay_net',net);
    setValue('pay_gros',gross);
  },[watch('pay'),ratio]);

  const onClose = useCallback(() => {
    setSelectedEmployee();
    setManagerModalIsOpen(false,null,null,null,null);
  },[setManagerModalIsOpen,setSelectedEmployee]);

  return (<Modal minWidth='498px' maxWidth='560px' title={!title ? 'Вознаграждение' : title} onClose={onClose}>
    <div className={cls['form']}>
      <div className={cls['employee-status']}>
        <p>Работник: {fi}</p>
        <p>Тип выплаты: {employeeType}</p>
      </div>
      <Input control={control} variant={EInputStyleVariant['basicInput']} type={'number'} disabled={fieldDisabled}
        label='Вознаграждение с учетом НДФЛ'
        placeholder='0 ₽' name='pay'
      />
      <div className={selectedEmployee?.employee_type === 1 ? cls['row'] : ''}>
        <Input variant={EInputStyleVariant['basicInput']} disabled label='На руки'
          placeholder='0 ₽' control={control} name='pay_net' type={'number'} />
        {selectedEmployee?.employee_type === 1 &&
          <Input variant={EInputStyleVariant['basicInput']} disabled label='НДФЛ' placeholder='0 ₽' control={control} name='pay_gros' type={'number'} />
        }
      </div>
      <div style={{ alignSelf: 'flex-end',marginTop: '16px',marginBottom: '-20px' }}>
        <ModalButtons primaryButtonLabel='Сохранить' onPrimaryButtonClick={handleSubmit(onSubmit)} secondaryButtonLabel='Отменить' onSecondaryButtonClick={onClose} />
      </div>
    </div>
  </Modal>);
};

export const ManagerModal = observer(ManagerModalComponent);