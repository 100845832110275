import { dkkProps, GetProfileFetch, setDkk } from "api/header";
import { userGetRolesOnSpot } from "api/roles";
import { makeAutoObservable } from "mobx";
import { IPermissions, IProfileData } from "pages/access-rights/types";
import { RootStore } from "store/rootStore";
import { showNotificationModal } from "ui-new/alert";

export class HeaderStore {
  rootStore: RootStore;
  constructor(rootStore: RootStore) {
    makeAutoObservable(this);
    this.rootStore = rootStore;
  }

  profileData: IProfileData | null = null;

  //Быстрое решение
  permissions: IPermissions | null = JSON.parse(
    localStorage.getItem("permissions") ?? "{}"
  );

  fetchGetProfile = async () => {
    const response = await GetProfileFetch();

    if (!response) return;

    this.profileData = response.data.data;
    this.fetchGetPermissions();
  };

  fetchSetDkk = async (body: dkkProps) => {
    const response = await setDkk(body);

    if (!response) return false;

    this.profileData = response.data;
    showNotificationModal({
      title: "Количество часов по ДКК успешно обновлено",
      type: "success",
    });

    return true;
  };

  fetchGetPermissions = async () => {
    if (!this.profileData) return;

    const response = await userGetRolesOnSpot(
      this.profileData.spot_id,
      this.profileData.id
    );

    if (!response) {
      this.permissions = null;
      localStorage.removeItem("permissions");
      return false;
    }

    localStorage.setItem("permissions", JSON.stringify(response.data));
    this.permissions = response.data;
  };
}
