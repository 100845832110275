export enum ValidationTypes {
  required,
  string,
  number,
  min,
  max,
  positive,
}

export const ValidationMessages = new Map<ValidationTypes, string>([
  [ValidationTypes.required, "Поле обязательное"],
  [ValidationTypes.min, "Минимум:"],
  [ValidationTypes.max, "Максимум:"],
  [ValidationTypes.number, "Необходимо число"],
  [ValidationTypes.positive, "Число должно быть положительным"],
]);
