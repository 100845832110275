export interface IStatus {
  name: string;
  name_ru: string;
  value: number;
  is_active: boolean;
}

export interface IJobTitle {
  id: number;
  title_en: string;
  title_ru: string;
}

export interface IUser {
  id: number;
  employee_type: number;
  name: string;
  surname: string;
  third_name: string | null;
  email: string;
  email_verified_at: null;
  phone: string | null;
  timezone: number;
  dkk: number;
  telegram: string | null;
  date_of_born: string | null;
  avatar: number | null;
  slogan: string | null;
  token: string | null;
  spot_id: number;
  job_title_id: IJobTitle["id"];
  inn: string;
  snils: string;
  payment_place: number;
  guid_1c: string | null;
  individual_guid: string | null;
  created_at: string;
  updated_at: string;
  deleted_at: null;
  login_at: string | null;
}

export interface IAward {
  id: string;
  user_id: IUser["id"];
  spot_id: number;
  section_status_id: number;
  plan_date: string | null;
  plan_gross: number | null;
  plan_net: number | null;
  fact_date: string | null;
  fact_gross: number | null;
  fact_net: number | null;
  approved_date: string | null;
  approved_gross: number | null;
  approved_net: number | null;
  paid_date: string | null;
  paid_gross: number | null;
  paid_net: number | null;
  taxes: number | null;

  //2024-08-26T22:54:45.000000Z - пример
  created_at: string;
  updated_at: string;
}

interface IDoubleAward {
  user_id: IUser["id"];
  spot_id: number;
  section_status_id: number;
  first_period_plan_date: string | null;
  first_period_plan_gross: number | null;
  first_period_plan_net: number | null;
  first_period_fact_date: string | null;
  first_period_fact_gross: number | null;
  first_period_fact_net: number | null;
  first_period_approved_date: string | null;
  first_period_approved_gross: number | null;
  first_period_approved_net: number | null;
  first_period_paid_date: string | null;
  first_period_paid_gross: number | null;
  first_period_paid_net: number | null;
  second_period_plan_date: string | null;
  second_period_plan_gross: number | null;
  second_period_plan_net: number | null;
  second_period_fact_date: string | null;
  second_period_fact_gross: number | null;
  second_period_fact_net: number | null;
  second_period_approved_date: string | null;
  second_period_approved_gross: number | null;
  second_period_approved_net: number | null;
  second_period_paid_date: string | null;
  second_period_paid_gross: number | null;
  second_period_paid_net: number | null;
  total_planned_gross: number | null;
  total_planned_net: number | null;
  total_fact_gross: number | null;
  total_fact_net: number | null;
  total_approved_gross: number | null;
  total_approved_net: number | null;
  total_paid_gross: number | null;
  total_paid_net: number | null;
}

export interface IShiftAward extends IDoubleAward {}

export interface IBonusAward extends IAward {}

export interface IContractStatus {
  name: string;
  name_ru: string;
  value: number;
}

export interface IContract {
  id: number;
  spot_id: number;
  user_id: IUser["id"];
  date: string;
  title: string;
  status: IContractStatus["value"];
  plan_award: number;
  fact_award: number;
  employee_comment: string | null;
  manager_comment: string | null;
  description: string | null;
  created_at: string;
  updated_at: string;
}

export interface IContractAward extends IAward {
  contract_id: IContract["id"];
  status: IStatus["value"];
}

export interface IBonusTableItem extends IBonusAward {
  user: string;
  job: IJobTitle["title_ru"];
}

export interface IContractTableItem extends IContractAward {
  contract: IContract["title"];
  contract_status: IContract["status"];
  user: string;
}

export interface IShiftTableItem extends IShiftAward {
  user: string;
  job: IJobTitle["title_ru"];
}

export interface IGeneralAward {
  first_period_approved: number | null;
  first_period_esn: number | null;
  first_period_fss: number | null;
  first_period_ndfl: number | null;
  first_period_plan: number | null;
  monthly_approved: number | null;
  monthly_pay: number | null;
  monthly_plan: number | null;
  monthly_tax_esn: number | null;
  monthly_tax_fss: number | null;
  monthly_tax_ndfl: number | null;
  second_period_approved: number | null;
  second_period_esn: number | null;
  second_period_fss: number | null;
  second_period_ndfl: number | null;
  second_period_plan: number | null;
  user_id: IUser["id"];
}

export interface IGeneralTableItem extends IGeneralAward {
  user: string;
  job: IJobTitle["title_ru"];
}

export interface IManagerAward extends IDoubleAward {
  id: string;
}
export interface IManagerTableItem extends IManagerAward {
  user: string;
  job: IJobTitle["title_ru"];
}

export enum FundTabs {
  "Общая информация" = 0,
  "Вознаграждения за смены",
  "Контракты",
  "Премии",
  "Управляющий",
}

export enum FieldTypes {
  "plan" = "plan",
  "fact" = "fact",
  "approved" = "approved",
  "paid" = "paid",
}
