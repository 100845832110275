import axios, { AxiosResponse } from "axios";
import Cookies from "js-cookie";
import { showNotificationModal } from "ui-new/alert";

export const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_BASE}/api/v3/`,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${Cookies.get("token")}`,
  },
});

api.interceptors.request.use(request => {
  request.headers.Authorization = `Bearer ${Cookies.get("token")}`;
  return request;
});

api.interceptors.response.use(
  (response: AxiosResponse) => response,
  error => {
    if (error.response.status === 401) {
      Cookies.remove("token");
      window.dispatchEvent(new Event("storage"));
    } else {
      if (error.response.status === 403) {
        showNotificationModal({
          title: "Ошибка прав доступа",
          type: "failure",
          errors: [
            "Не соответствие прав доступа, невозможно выполнить операцию",
          ],
        });
      }
    }
    return Promise.reject(error);
  }
);
