import { api, IBaseResponse, IResponseWithErrors } from "./api";
import axios, { AxiosResponse } from "axios";
import { IProfileData } from "pages/access-rights/types";
import { showNotificationModal } from "ui-new/alert";

export async function GetProfileFetch(): Promise<AxiosResponse<{
  success: boolean;
  data: IProfileData;
}> | null> {
  try {
    return await api.get(`profile`);
  } catch (err: any) {
    return null;
  }
}

export async function fetchLogout() {
  try {
    const response = await api.get(`logout`);
    return response.data;
  } catch (err) {
    return null;
  }
}

export interface dkkProps {
  hours: number;
  fixPay: number;
}

export async function setDkk(body: dkkProps) {
  try {
    const response = await api.post<IBaseResponse<IProfileData>>(
      `user/setDkk`,
      body
    );
    return response.data;
  } catch (error) {
    if (axios.isAxiosError(error) && error.response) {
      if (error.response.data.message) {
        const errResponse = error.response.data as IResponseWithErrors;
        showNotificationModal({
          title: errResponse.message,
          type: "failure",
          errors: errResponse.errors,
        });
      }
    }
    return null;
  }
}
