import { yupResolver } from '@hookform/resolvers/yup';
import { ValidationMessages,ValidationTypes } from 'assets/utils/validationMessages';
import { observer } from 'mobx-react-lite';
import { FC,useEffect,useMemo,useRef,useState } from 'react';
import { useForm } from 'react-hook-form';
import { EInputStyleVariant,Input } from 'ui-new/input';
import SelectField from 'ui-new/select';
import { Textarea } from 'ui-new/textarea';
import { Modal } from 'widgets/modal';
import * as yup from 'yup';
import cls from './styles.module.scss';
import { ModalButtons } from 'widgets/modal/buttons';
import { BucketIcon,CheckmarkIcon,TimeIcon } from 'pages/salary-fund/icons';
import { useStore } from 'store';
import { ContractsTooltip } from '../tooltip';
import { toJS } from 'mobx';

const schema = yup.object({
  title: yup.string().required(ValidationMessages.get(ValidationTypes.required))
    .max(50,({ max }) => ValidationMessages.get(ValidationTypes.max) + ` ${max} символов`),
  plan_award: yup.number().typeError(ValidationMessages.get(ValidationTypes.number) ?? '')
    .max(999999.99,({ max }) => ValidationMessages.get(ValidationTypes.max) + ` ${max}`)
    .when('status_id',(deps,schema) => {
      const statusId = deps[0];
      if ([2,3].includes(statusId)) {
        return schema.nullable();
      }
      return schema.required(ValidationMessages.get(ValidationTypes.required));
    }),
  status_id: yup.number().nullable(),
  fact_award: yup.number().nullable().when('status_id',(deps,schema) => {
    const statusId = deps[0];

    if (statusId === 3) {
      return schema.nonNullable(ValidationMessages.get(ValidationTypes.required)).required();
    }
    return schema;
  }),
  user_id: yup.number().required(ValidationMessages.get(ValidationTypes.required)),
  description: yup.string().nullable(),
  manager_comment: yup.string().nullable(),
});

type FormValues = {
  title: string;
  plan_award?: number;
  fact_award?: number | null;
  user_id: number;
  description?: string | null;
  status_id?: number | null;
  manager_comment?: string | null;
};

interface EditContractProps {
  onClose: () => void;
}

const EditContractComponent: FC<EditContractProps> = ({ onClose }) => {
  const { RootStore: { SalaryFundStore: { cancelContractConfirm,editContractConfirm,
    ndfl,editableContract,contracts,users,contractStatuses,
    approveContractConfirm } } } = useStore();
  const [netTaxPlan,setNetTaxPlan] = useState({ net: 0,tax: 0 });
  const [netTaxFact,setNetTaxFact] = useState({ net: 0,tax: 0 });

  const currentContract = useMemo(() => contracts.find(c => c.id === editableContract?.contract_id),[contracts,editableContract?.contract_id]);

  const usersData = users.map(u => ({ value: u.id,label: `${u.surname ?? ''} ${u.name ?? ''}` }));
  const contractStatus = contractStatuses.find(c => c.value === editableContract?.contract_status);
  const allDisabled = ([4,5] as any[]).includes(editableContract?.contract_status) || ([3,4] as any[]).includes(editableContract?.status);

  const { handleSubmit,control,watch,getValues } = useForm<FormValues>({
    resolver: yupResolver(schema),defaultValues: (editableContract && currentContract) ? {
      title: currentContract.title,
      plan_award: editableContract.plan_gross ?? 0,
      fact_award: editableContract.fact_gross ?? 0,
      user_id: currentContract.user_id,
      description: currentContract.description,
      status_id: currentContract?.status,
      manager_comment: currentContract?.manager_comment
    } : {}
  });

  const wrapperRef = useRef<HTMLDivElement | null>(null);
  // useEffect(() => {
  //   console.log(toJS(currentContract),toJS(editableContract));


  //   if (!currentContract && !editableContract) return;

  //   reset({
  //     title: currentContract!.title,
  //     plan_award: editableContract!.plan_gross ?? 0,
  //     fact_award: editableContract!.fact_gross,
  //     user_id: currentContract!.user_id,
  //     description: currentContract!.description,
  //     status_id: currentContract!.status,
  //     manager_comment: currentContract!.manager_comment
  //   });
  // },[currentContract,editableContract,reset]);

  useEffect(() => {
    const div = wrapperRef.current;

    if (!div) return;


    const onResizeHandler = (e: UIEvent) => {
      div.style.maxHeight = `${window.innerHeight * 0.7}px`;
    };

    window.addEventListener('resize',onResizeHandler);
    return () => window.removeEventListener('resize',onResizeHandler);
  },[]);

  useEffect(() => {
    const cost = getValues().plan_award ?? 0;
    if (isNaN(Number(cost))) {
      setNetTaxPlan({ net: 0,tax: 0 });
      return;
    }

    const net = Math.round(cost * ndfl * 100) / 100;
    const tax = Math.round(cost * (1 - ndfl) * 100) / 100;

    setNetTaxPlan({ net,tax });
  },[watch('plan_award')]);

  useEffect(() => {
    const cost = getValues().fact_award!;
    if (isNaN(Number(cost))) {
      setNetTaxFact({ net: 0,tax: 0 });
      return;
    }

    const net = Math.round(cost * ndfl * 100) / 100;
    const tax = Math.round(cost * (1 - ndfl) * 100) / 100;

    setNetTaxFact({ net,tax });
  },[watch('fact_award')]);

  const onSave = (values: FormValues) => {
    console.log(values);


    if (!editableContract) return;
    if (currentContract?.status === 3) {
      approveContractConfirm({ ...values,id: editableContract.contract_id },onClose);
      return;
    }
    editContractConfirm({ ...values,id: editableContract.contract_id },onClose);
  };

  return (<Modal title='Редактировать контракт' maxWidth='500px' onClose={onClose}>
    <div className={cls['edit-contract-form']}>
      <div className={cls['edit-contract-wrapper']} ref={wrapperRef} style={{ maxHeight: `${window.innerHeight * 0.7}px` }}>
        <p className={cls['status']}>Статус: {contractStatus?.name_ru ?? 'неизвестен'}</p>
        {((([3,4,5] as any).includes(contractStatus?.value)) && currentContract?.employee_comment) &&
          <div className={`${cls['comment']} ${contractStatus?.value === 3 ? cls['comment-processed'] : cls['comment-done']}`}
            style={{ marginBottom: '24px' }} >
            <div className={cls['comment-icon']}>
              {
                contractStatus?.value === 3 ?
                  <CheckmarkIcon />
                  :
                  <TimeIcon />
              }
            </div>
            <div>
              <h6>Комментарий работника о выполнении</h6>
              <p>{currentContract?.employee_comment}</p>
            </div>
          </div>}
        <Input variant={EInputStyleVariant.basicInput} label='Название контракта' control={control}
          type='text' name='title' placeholder='Введи название контракта' disabled={allDisabled} />
        <div className={cls['row']}>
          <ContractsTooltip net={netTaxPlan.net} tax={netTaxPlan.tax}>
            <Input variant={EInputStyleVariant.basicInput} label='План, ₽' control={control}
              type='number' name='plan_award' placeholder='0 ₽' inputProps={{ style: { fontFamily: 'Inter' } }}
              disabled={allDisabled}
            />
          </ContractsTooltip>
          <ContractsTooltip net={netTaxFact.net} tax={netTaxFact.tax}>
            <Input variant={EInputStyleVariant.basicInput} label='Факт, ₽' control={control}
              type='number' name='fact_award' placeholder='0 ₽' inputProps={{ style: { fontFamily: 'Inter' } }}
              disabled={allDisabled}
            />
          </ContractsTooltip>
        </div>
        <SelectField name='user_id' control={control} label='Ответственный'
          selectProps={{ placeholder: 'Выбери ответственного',notFoundContent: 'Не найден' }}
          options={usersData}
          disabled={allDisabled}
        // disabled={allDisabled || ([2,3] as any[]).includes(contractStatus?.value)}
        />
        <Textarea rows={3} label='Описание контракта' control={control} name='description' placeholder='Расскажи, какие задачи входят в этот контракт'
          disabled={allDisabled}
        />
        {!([1,2] as any[]).includes(currentContract?.status) &&
          <Textarea rows={3} label='Комментарий управляющего' disabled={allDisabled} control={control} name='manager_comment' placeholder='Информация управляющего о выполнении контракта' />
        }
      </div>
      <div className={cls['buttons']}>
        <button className={cls['edit-contract__delete']} onClick={cancelContractConfirm} disabled={allDisabled}>
          <BucketIcon color={'#EB5757'} /> Удалить контракт
        </button>
        <ModalButtons className={cls['buttons']} onPrimaryButtonClick={handleSubmit(onSave,(val,x) => { console.log(getValues(),toJS(editableContract)); })}
          onSecondaryButtonClick={currentContract?.status === 3 ? undefined : onClose}
          primaryButtonLabel={currentContract?.status === 3 ? 'Подтвердить выполнение' : 'Сохранить'}
          secondaryButtonLabel={currentContract?.status === 3 ? undefined : 'Отменить'}
          primaryButtonDisabled={allDisabled}
        />
      </div>
    </div>
  </Modal>);
};

export const EditContract = observer(EditContractComponent);