import { FC } from 'react';

interface IconProps {
  color?: string;
}

export const PlusIcon: FC<IconProps> = ({ color }) => {
  const fillColor = color ?? '#6D37F6';

  return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.6998 0.999805C6.6998 0.613205 6.3864 0.299805 5.9998 0.299805C5.61321 0.299805 5.2998 0.613205 5.2998 0.999805L5.2998 5.2998H0.999805C0.613205 5.2998 0.299805 5.6132 0.299805 5.9998C0.299805 6.3864 0.613205 6.6998 0.999805 6.6998H5.2998V10.9998C5.2998 11.3864 5.6132 11.6998 5.9998 11.6998C6.3864 11.6998 6.6998 11.3864 6.6998 10.9998L6.6998 6.6998H10.9998C11.3864 6.6998 11.6998 6.3864 11.6998 5.9998C11.6998 5.6132 11.3864 5.2998 10.9998 5.2998H6.6998V0.999805Z" fill={fillColor} />
  </svg>;
};