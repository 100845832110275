import { FC,useMemo } from 'react';
import cls from '../styles.module.scss';
import { addSpaces } from 'assets/utils';
import { IShiftTableItem } from '../types';
import { observer } from 'mobx-react-lite';
import { useStore } from 'store';

interface EmployeesTableProps {
  className?: string;
  title: string;
  shiftList: IShiftTableItem[];
}

const EmployeesTableComponent: FC<EmployeesTableProps> = ({ className,shiftList,title }) => {
  const { RootStore: { SalaryFundStore: { netOrGross } } } = useStore();
  const sum = useMemo(() => {
    let firstPlanSum = 0;
    let firstFactSum = 0;
    let secondPlanSum = 0;
    let secondFactSum = 0;
    let approveSum = 0;
    let paidSum = 0;
    shiftList.forEach((val) => {
      firstPlanSum += val[`first_period_plan_${netOrGross}`] ?? 0;
      firstFactSum += val[`first_period_fact_${netOrGross}`] ?? 0;
      secondPlanSum += val[`second_period_plan_${netOrGross}`] ?? 0;
      secondFactSum += val[`second_period_fact_${netOrGross}`] ?? 0;
      approveSum += val[`total_approved_gross`] ?? 0;
      paidSum += val[`total_paid_gross`] ?? 0;
    });
    firstPlanSum = Math.floor(firstPlanSum * 100) / 100;
    firstFactSum = Math.floor(firstFactSum * 100) / 100;
    secondPlanSum = Math.floor(secondPlanSum * 100) / 100;
    secondFactSum = Math.floor(secondFactSum * 100) / 100;
    approveSum = Math.floor(approveSum * 100) / 100;
    paidSum = Math.floor(paidSum * 100) / 100;
    return [firstPlanSum,firstFactSum,secondPlanSum,secondFactSum,approveSum,paidSum];
  },[shiftList,netOrGross]);

  const sumData = useMemo(() => sum.map(s => <td className={cls['td']}>{addSpaces(s)}</td>),[sum]);

  const employeesData = useMemo(() => shiftList.map((shift,i) => {
    const { user,job } = shift;
    return <tr>
      <td className={`${cls['td']} ${cls['employee']} ${cls['num']}`}>{i + 1}</td>
      <td className={`${cls['td']} ${cls['employee']}`}><span title={user}>{user}</span></td>
      <td className={`${cls['td']} ${cls['employee']}`}><span title={job}>{job}</span></td>
      <td className={`${cls['td']}`}>{addSpaces(shift[`first_period_plan_${netOrGross}`] ?? 0)}</td>
      <td className={`${cls['td']}`}>{addSpaces(shift[`first_period_fact_${netOrGross}`] ?? 0)}</td>
      <td className={`${cls['td']}`}>{addSpaces(shift[`second_period_plan_${netOrGross}`] ?? 0)}</td>
      <td className={`${cls['td']}`}>{addSpaces(shift[`second_period_fact_${netOrGross}`] ?? 0)}</td>
      <td className={`${cls['td']}`}>{addSpaces(shift[`total_approved_${netOrGross}`] ?? 0)}</td>
      <td className={`${cls['td']} ${cls['td-bold']}`}>{addSpaces(shift[`total_paid_${netOrGross}`] ?? 0)}</td>
    </tr>;
  }),[shiftList,netOrGross]);

  return (
    <table className={`${cls['table']} ${className ?? ''}`}>
      <colgroup>
        <col width={44} />
        <col width={207} />
        <col width={181} />
        <col width={115} />
        <col width={115} />
        <col width={115} />
        <col width={115} />
        <col width={115} />
        <col width={115} />
      </colgroup>
      <thead className={`${cls['thead']} ${cls['rounding']}`}>
        <tr className={cls['tr']}>
          <th className={cls['th']} colSpan={3}>{title}</th>
          <th className={cls['th']} colSpan={2}>Аванс - Сумма, ₽</th>
          <th className={cls['th']} colSpan={2}>Зарплата - Сумма, ₽</th>
          <th className={cls['th']} colSpan={2}>Всего за месяц</th>
        </tr>
        <tr className={cls['tr']}>
          <th className={cls['th']}>№</th>
          <th className={cls['th']}>Работник</th>
          <th className={cls['th']}>Должность</th>
          <th className={cls['th']}>План</th>
          <th className={cls['th']}>Факт</th>
          <th className={cls['th']}>План</th>
          <th className={cls['th']}>Факт</th>
          <th className={cls['th']}>Утверждено</th>
          <th className={cls['th']}>Выплачено</th>
        </tr>
      </thead>
      <tfoot className={cls['tfoot']}>
        <tr className={cls['tr']}>
          <td className={cls['td']} colSpan={3}>Всего</td>
          {sumData}
        </tr>
      </tfoot>
      <tbody className={cls['tbody']}>
        {employeesData}
      </tbody>
    </table>
  );
};

export const EmployeesTable = observer(EmployeesTableComponent);