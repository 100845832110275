import { FC } from 'react';

interface IconProps {
  color?: string;
}

export const BucketIcon: FC<IconProps> = ({ color }) => {
  const fillColor = color ?? '#999999';

  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3 3.33325V14.6666H13V3.33325H3Z" stroke={fillColor} stroke-width="1.4" stroke-linejoin="round" />
    <path d="M6.66699 6.66675V11.0001" stroke={fillColor} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M9.33301 6.66675V11.0001" stroke={fillColor} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1.33301 3.33325H14.6663" stroke={fillColor} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M5.33301 3.33325L6.42933 1.33325H9.59205L10.6663 3.33325H5.33301Z" stroke={fillColor} stroke-width="1.4" stroke-linejoin="round" />
  </svg>;

};

export const InfoIcon: FC<IconProps> = ({ color }) => {
  const fillColor = color ?? "#98A1B2";

  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.00065 14.6673C9.8416 14.6673 11.5083 13.9211 12.7147 12.7147C13.9211 11.5083 14.6673 9.8416 14.6673 8.00065C14.6673 6.1597 13.9211 4.49304 12.7147 3.28661C11.5083 2.08018 9.8416 1.33398 8.00065 1.33398C6.1597 1.33398 4.49304 2.08018 3.28661 3.28661C2.08018 4.49304 1.33398 6.1597 1.33398 8.00065C1.33398 9.8416 2.08018 11.5083 3.28661 12.7147C4.49304 13.9211 6.1597 14.6673 8.00065 14.6673Z" stroke={fillColor} stroke-width="1.4" stroke-linejoin="round" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.99935 3.66732C8.45959 3.66732 8.83268 4.04041 8.83268 4.50065C8.83268 4.96089 8.45959 5.33398 7.99935 5.33398C7.53911 5.33398 7.16602 4.96089 7.16602 4.50065C7.16602 4.04041 7.53911 3.66732 7.99935 3.66732Z" fill={fillColor} />
    <path d="M8.16667 11.334V6.66732H7.83333H7.5" stroke={fillColor} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M7 11.3333H9.33333" stroke={fillColor} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
  </svg>;

};

export const CheckmarkIcon: FC<IconProps> = ({ color }) => {
  const fillColor = color ?? "#6D37F6";

  return <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 6.5L5.09091 11L11 1" stroke={fillColor} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M1 6.5L5.09091 11L11 1" stroke="black" stroke-opacity="0.2" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
    ;
};

export const TimeIcon: FC<IconProps> = ({ color }) => {
  const fillColor = color ?? 'black';

  return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.00016 14.6666C11.6821 14.6666 14.6668 11.6818 14.6668 7.99992C14.6668 4.31802 11.6821 1.33325 8.00016 1.33325C4.31826 1.33325 1.3335 4.31802 1.3335 7.99992C1.3335 11.6818 4.31826 14.6666 8.00016 14.6666Z" stroke={fillColor} stroke-width="1.4" stroke-linejoin="round" />
    <path d="M8.00016 14.6666C11.6821 14.6666 14.6668 11.6818 14.6668 7.99992C14.6668 4.31802 11.6821 1.33325 8.00016 1.33325C4.31826 1.33325 1.3335 4.31802 1.3335 7.99992C1.3335 11.6818 4.31826 14.6666 8.00016 14.6666Z" stroke={fillColor} stroke-opacity="0.2" stroke-width="1.4" stroke-linejoin="round" />
    <path d="M8.00314 4.00003L8.00274 8.00298L10.8292 10.8294" stroke={fillColor} stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
    <path d="M8.00314 4.00003L8.00274 8.00298L10.8292 10.8294" stroke={fillColor} stroke-opacity="0.2" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round" />
  </svg>;
};