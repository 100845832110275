import { observer } from "mobx-react-lite";
import cls from './styles.module.scss';
import { FundHeader } from './components/header';
import { GeneralInformation } from './general/general-information';
import { useEffect,useMemo,useState } from 'react';
import { FundTabs } from './types';
import { BonusInformation } from './bonus/bonus-information';
import { Bounty } from './bounty-shifts/bounty-information';
import { ManagerInfo } from './manager/manager-info';
import { ContractsInfo } from './contracts/contracts-info';
import { useStore } from 'store';
import { Modals } from './modals/modals';

let generalTimer: number;
let timer: number;
let shiftsTimer: number;
let contractTimer: number;
let managerTimer: number;
const SalaryFundComponent = () => {
  const { RootStore: { SalaryFundStore: { fetchGetInfo,fetchGetBonuses,setTableGeneralItems,setTableBonusItems,setTableShiftItems,setTableContractItems,setTableManagerItems,users,jobTitles,bonusList,contractAwards,contracts } } } = useStore();
  const [activeTab,setActiveTab] = useState(FundTabs['Общая информация']);

  useEffect(() => {
    fetchGetInfo();
  },[fetchGetBonuses,fetchGetInfo]);

  useEffect(() => {
    generalTimer = window.setTimeout(setTableGeneralItems,100);
    return () => window.clearTimeout(generalTimer);
  },[setTableGeneralItems,users,jobTitles,bonusList]);

  useEffect(() => {
    timer = window.setTimeout(setTableBonusItems,100);
    return () => window.clearTimeout(timer);
  },[setTableBonusItems,users,jobTitles,bonusList]);

  useEffect(() => {
    shiftsTimer = window.setTimeout(setTableShiftItems,100);
    return () => window.clearTimeout(shiftsTimer);
  },[setTableShiftItems,users,jobTitles,bonusList]);

  useEffect(() => {
    contractTimer = window.setTimeout(setTableContractItems,100);
    return () => window.clearTimeout(contractTimer);
  },[setTableContractItems,users,jobTitles,contractAwards,contracts]);

  useEffect(() => {
    managerTimer = window.setTimeout(setTableManagerItems,100);
    return () => window.clearTimeout(managerTimer);
  },[setTableManagerItems,users,jobTitles,contractAwards,contracts]);

  const activeView = useMemo(() => {
    switch (activeTab) {
      case FundTabs['Общая информация']:
        return <GeneralInformation />;
      case FundTabs['Вознаграждения за смены']:
        return <Bounty />;
      case FundTabs['Контракты']:
        return <ContractsInfo />;
      case FundTabs['Премии']:
        return <BonusInformation />;
      case FundTabs['Управляющий']:
        return <ManagerInfo />;
      default:
        return null;
    }
  },[activeTab]);

  return <>
    <div className={cls['fund__wrapper']}>
      <h1 className={cls['fund-title']}>Вознаграждения</h1>
      <FundHeader activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeView}
    </div>
    <Modals />
  </>;
};

export const SalaryFundPage = observer(SalaryFundComponent);