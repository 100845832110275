import { observer } from 'mobx-react-lite';
import { EmployeesTable } from './employees-table';
import { useEffect,useMemo } from 'react';
import cls from '../styles.module.scss';
import { addSpaces } from 'assets/utils';
import { useStore } from 'store';


const BountyComponent = () => {
  const { RootStore: { SalaryFundStore: { tableShiftItems: { helpers,users },netOrGross,selectedDate,fetchGetShifts } } } = useStore();

  const sum = useMemo(() => {
    if (!users.length || !helpers.length) return [0,0,0,0];

    let firstPlanSum = 0;
    let firstFactSum = 0;
    let secondPlanSum = 0;
    let secondFactSum = 0;
    let approveSum = 0;
    let paidSum = 0;
    users.forEach((val) => {
      firstPlanSum += val[`first_period_plan_${netOrGross}`] ?? 0;
      firstFactSum += val[`first_period_fact_${netOrGross}`] ?? 0;
      secondPlanSum += val[`second_period_plan_${netOrGross}`] ?? 0;
      secondFactSum += val[`second_period_fact_${netOrGross}`] ?? 0;
      approveSum += val[`total_approved_gross`] ?? 0;
      paidSum += val[`total_paid_gross`] ?? 0;
    });
    helpers.forEach((val) => {
      firstPlanSum += val[`first_period_plan_${netOrGross}`] ?? 0;
      firstFactSum += val[`first_period_fact_${netOrGross}`] ?? 0;
      secondPlanSum += val[`second_period_plan_${netOrGross}`] ?? 0;
      secondFactSum += val[`second_period_fact_${netOrGross}`] ?? 0;
      approveSum += val[`total_approved_gross`] ?? 0;
      paidSum += val[`total_paid_gross`] ?? 0;
    });

    firstPlanSum = Math.floor(firstPlanSum * 100) / 100;
    firstFactSum = Math.floor(firstFactSum * 100) / 100;
    secondPlanSum = Math.floor(secondPlanSum * 100) / 100;
    secondFactSum = Math.floor(secondFactSum * 100) / 100;
    approveSum = Math.floor(approveSum * 100) / 100;
    paidSum = Math.floor(paidSum * 100) / 100;
    return [firstPlanSum,firstFactSum,secondPlanSum,secondFactSum,approveSum,paidSum];
  },[users,helpers,netOrGross]);
  const sumData = useMemo(() => sum.map(s => <td className={cls['td']}>{addSpaces(s)}</td>),[sum]);

  const footerIsVisible = helpers.length > 0 && users.length > 0;

  useEffect(() => {
    fetchGetShifts();
  },[fetchGetShifts,selectedDate]);

  return (<div>
    {users.length > 0 && <EmployeesTable title='Работники' className={cls['table-rounding__top']} shiftList={users} />}
    {helpers.length > 0 && <EmployeesTable title='Хелперы' className={users.length === 0 ? cls['table-rounding__top'] : ''} shiftList={helpers} />}
    {footerIsVisible && <table style={{ position: 'sticky',bottom: '0' }} className={`${cls['table']} ${cls['table-rounding__bottom']}`}>
      <colgroup>
        <col width={44} />
        <col width={207} />
        <col width={181} />
        <col width={115} />
        <col width={115} />
        <col width={115} />
        <col width={115} />
        <col width={115} />
        <col width={115} />
      </colgroup>
      <tbody className={`${cls['tfoot']} ${cls['total-tfoot']} ${cls['rounding']}`}>
        <tr className={cls['tr']}>
          <td className={cls['td']} colSpan={3}>Всего</td>
          {sumData}
        </tr>
      </tbody>
    </table>}
  </div>);
};

export const Bounty = observer(BountyComponent);