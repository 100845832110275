import { observer } from 'mobx-react-lite';
import { FC,useCallback,useEffect,useMemo,useState } from 'react';
import { useForm } from 'react-hook-form';
import { EInputStyleVariant,Input } from 'ui-new/input';
import SelectField from 'ui-new/select';
import { Textarea } from 'ui-new/textarea';
import { Modal } from 'widgets/modal';
import { ModalButtons } from 'widgets/modal/buttons';
import cls from './styles.module.scss';
import * as yup from 'yup';
import { ValidationMessages,ValidationTypes } from 'assets/utils/validationMessages';
import { yupResolver } from '@hookform/resolvers/yup';
import { useStore } from 'store';
import { ContractsTooltip } from '../tooltip';
import { IUser } from 'pages/salary-fund/types';
import dayjs from 'dayjs';

const schema = yup.object({
  title: yup.string().required(ValidationMessages.get(ValidationTypes.required))
    .max(50,({ max }) => ValidationMessages.get(ValidationTypes.max) + ` ${max} символов`),
  plan_award: yup.number().typeError(ValidationMessages.get(ValidationTypes.number) ?? '').nullable()
    .max(999999.99,({ max }) => ValidationMessages.get(ValidationTypes.max) + ` ${max}`),
  user_id: yup.number().required(ValidationMessages.get(ValidationTypes.required)),
  description: yup.string().nullable(),
});

interface FormValues {
  title: string;
  user_id: IUser["id"];
  plan_award?: number | null;
  description?: string | null;
}

interface AddContractProps {
  onClose: () => void;
}

const AddContractComponent: FC<AddContractProps> = ({ onClose }) => {
  const { RootStore: { SalaryFundStore: { fetchCreateContract,ndfl,selectedDate,users } } } = useStore();
  const [netTax,setNetTax] = useState({ net: 0,tax: 0 });

  const { handleSubmit,control,watch,getValues } = useForm<FormValues>({ resolver: yupResolver(schema),defaultValues: { description: null,plan_award: null } });

  useEffect(() => {
    const cost = getValues().plan_award;
    if (!cost) return;

    if (isNaN(Number(cost))) {
      setNetTax({ net: 0,tax: 0 });
      return;
    }

    const net = Math.round(cost * ndfl * 100) / 100;
    const tax = Math.round(cost * (1 - ndfl) * 100) / 100;

    setNetTax({ net,tax });
  },[watch('plan_award')]);

  const responsibles = useMemo(() => users.map(user => ({
    label: `${user.surname ?? ''} ${user.name ?? ''} ${user.third_name ?? ''}`,
    value: user.id
  })),[users]);

  const onSave = useCallback((values: FormValues) => {
    fetchCreateContract({
      ...values,description: values.description ?? null,
      plan_award: values.plan_award ?? 0,
      date: dayjs(selectedDate).format('YYYY-MM-01')
    }).then(onClose);
  },[fetchCreateContract,onClose,selectedDate]);

  return (<Modal title='Добавить контракт' maxWidth='480px' minWidth='480px' onClose={onClose}>
    <div className={cls['add-contract-form']}>
      <div className={cls['add-contract-wrapper']}>
        <Input variant={EInputStyleVariant.basicInput} label='Название контракта' control={control}
          type='text' name='title' placeholder='Введи название контракта' />
        <ContractsTooltip net={netTax.net} tax={netTax.tax}>
          <Input variant={EInputStyleVariant.basicInput} label='Вознаграждение по плану, ₽' control={control}
            type='number' name='plan_award' placeholder='0 ₽' inputProps={{ style: { fontFamily: 'Inter' } }} />
        </ContractsTooltip>
        <SelectField name='user_id' control={control} label='Ответственный'
          selectProps={{ placeholder: 'Выбери ответственного',notFoundContent: 'Не найден' }}
          options={responsibles}
        />
        <Textarea rows={3} label='Описание контракта' control={control} name='description' placeholder='Расскажи, какие задачи входят в этот контракт' />
      </div>
      <ModalButtons className={cls['buttons']} onPrimaryButtonClick={handleSubmit(onSave)} onSecondaryButtonClick={onClose} primaryButtonLabel='Сохранить' secondaryButtonLabel='Отменить' />
    </div>
  </Modal>);
};

export const AddContract = observer(AddContractComponent);